import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Megalith, MegalithContext } from "./stores/Megalith";
import {
  createTheme,
  MantineProvider,
  Container,
  TextInput,
  CopyButton,
  Button,
  Paper,
  Textarea,
  List,
  Divider,
  Stack,
  Image,
  Box,
  Anchor,
} from "@mantine/core";
import { Title, Text } from "@mantine/core";
import { format } from "d3-format";
import { formatPriceForMillions } from "./helpers/numbers";
import { MEGALITH_NODE_ADDRESS } from "./helpers/constants";
import BuyChannel from "./components/BuyChannel";
import { IconBrandTelegram, IconCopy, IconQrcode } from "@tabler/icons-react";
import QRCode from "react-qr-code";

const theme = createTheme({
  /** Put your mantine theme override here */
});

const MegalithContainer: React.FC = observer(() => {
  const megalith = useContext<Megalith>(MegalithContext);
  const [showQRCode, setShowQRCode] = useState(false);

  useEffect(() => {
    console.log("starting megalith container");
    megalith.hydrateDataFromPage();
  }, []);

  const makeBitcoinAmount = () => {
    const btcSize =
      megalith.megalithDataFrozen?.data.megalith_node_info.size_btc;
    return format(".1f")(btcSize);
  };

  const makeDollarAmount = () => {
    const btcSize =
      megalith.megalithDataFrozen?.data.megalith_node_info.size_btc;
    console.log("btc size", btcSize);
    const btcRate = megalith.megalithDataFrozen?.data.btc_usd_price;
    console.log("btc rate", btcRate);
    return formatPriceForMillions(btcSize * btcRate);
  };

  return (
    <>
      {megalith.hasLoaded && (
        <>
          <Stack>
            <Paper withBorder p="md">
              <Image
                mb="md"
                h={150}
                w="auto"
                fit="contain"
                radius="md"
                src="https://d3u5cu1kr5hxm.cloudfront.net/images/megalith-logo-400.webp"
                style={{ verticalAlign: "middle", marginRight: "10px" }}
              />
              <Title order={2}> The Megalith Node</Title>
              {/* <Text size="md">👋, thanks for visiting.</Text> */}
              <Text size="md">
                Megalith is one of the biggest and most stable routing nodes on
                the Lightning Network.{" "}
              </Text>
              <Text size="md">
                It's is powered by the relentless ☀️ sun of the American
                Southwest.
              </Text>
              <Text size="md">Notable features:</Text>
              {/* <Text size="md">It runs Freedom Tech™.</Text> */}

              <Text size="md"></Text>
              <List>
                <List.Item>Running in a secure datacenter</List.Item>
                <List.Item>99.9999% uptime</List.Item>
                <List.Item>Trunk fiber connection</List.Item>
                <List.Item>Fast global pings</List.Item>

                <List.Item>
                  {" " + makeDollarAmount()} ({makeBitcoinAmount() + " BTC"})
                  capacity
                </List.Item>
                <List.Item>
                  {
                    megalith.megalithDataFrozen?.data.megalith_node_info
                      .channels
                  }{" "}
                  open channels
                </List.Item>
                <List.Item>
                  Industry-leading LSP (Lightning Service Provider)
                </List.Item>
              </List>
            </Paper>
            <Paper>
              {/* <Title order={2}>🌴 How can the Megalith Node help you?</Title> */}
              {/* 
              <Text size="md">
                We recommend that you
                <Text fw={700} span>
                  {" "}
                  open a channel{" "}
                </Text>
                to Megalith.
              </Text> */}
            </Paper>
            <Paper withBorder p="md" mb="md">
              <Title order={4}>✨ Want to try running your own node?</Title>
              <Anchor href="https://rizful.com">
                <Image
                  h={100}
                  w="auto"
                  fit="contain"
                  radius="md"
                  src="https://daxwktpz4hkwd.cloudfront.net/logos/rizful-logo-mobile.png"
                  style={{ verticalAlign: "middle", marginRight: "10px" }}
                />
              </Anchor>
              <Text size="md">
                We've recently released{" "}
                <Anchor href="https://rizful.com">Rizful.com</Anchor>, a
                super-fast and easy way to get started with Lightning and Nostr.
                Instantly run a fully-featured Lighthing node in the cloud. No
                technical expertise required.
              </Text>
            </Paper>

            <Paper withBorder p="md" mb="md">
              <Title order={4}>✨ Get on NOSTR today</Title>
              <Anchor href="https://rizful.com/get_on_nostr_today">
                <Image
                  h={200}
                  w="auto"
                  fit="contain"
                  radius="md"
                  src="https://daxwktpz4hkwd.cloudfront.net/onboarding/nostr_app_running_on_your_phone.webp"
                  style={{ verticalAlign: "middle", marginRight: "10px" }}
                />
              </Anchor>
              <Text mt="md" size="md">
                Nostr is decentralized, uncensorable, free, and a lot of fun.
                It's the future's social network, available today.{" "}
                <Anchor href="https://rizful.com/get_on_nostr_today">
                  Follow this guide to get on Nostr
                </Anchor>
                .
              </Text>
            </Paper>

            <Paper withBorder p="md" mb="md">
              <Title order={4}>✅ Open a channel to The Megalith Node...</Title>

              <Textarea
                radius="md"
                mt="8"
                mb="8"
                maw="60%"
                readOnly
                label="Megalith Node Address"
                value={MEGALITH_NODE_ADDRESS}
                onFocus={(e) => e.currentTarget.select()}
              />
              <CopyButton value={MEGALITH_NODE_ADDRESS}>
                {({ copied, copy }) => (
                  <Button
                    leftSection={<IconCopy />}
                    color={copied ? "teal" : "blue"}
                    onClick={copy}
                  >
                    {copied ? "Copied!" : "Copy address"}
                  </Button>
                )}
              </CopyButton>
              <Button
                leftSection={<IconQrcode />}
                ml="sm"
                onClick={() => setShowQRCode(!showQRCode)}
              >
                {showQRCode ? "Hide QR Code" : "QR Code"}
              </Button>
              {showQRCode && (
                <Paper withBorder m="md" p="md">
                  <QRCode value={MEGALITH_NODE_ADDRESS} />
                </Paper>
              )}
              <Text mt="md" size="sm" c="dimmed">
                - minimum channel size: 1,000,000 satoshis
              </Text>
              <Text size="sm" c="dimmed">
                - need a smaller channel?{" "}
                <Anchor href="https://docs.megalithic.me/lightning-services/dealing-with-minimum-channel-sizes">
                  Open to our "small channel" node
                </Anchor>
              </Text>
              <Text size="sm" c="dimmed">
                - opening a channel is free
              </Text>
              <Text size="sm" c="dimmed">
                - <Anchor href="/contact">contact us </Anchor>
                for help
              </Text>
            </Paper>

            {/* <Paper withBorder p="md" mb="md">
              <Title order={4}>
                ☑️ Improve your RECEIVE payment success rate
              </Title>
              {/* <Text size="md">get a FREE INBOUND channel from Megalith:</Text> */}
            {/* <Text mt="lg" size="md">
                💡 The Megalith Node offers free incoming channels for any node
                that can demonstrate that it receives significant amount &
                frequency of lightning payments.{" "}
              </Text> */}
            {/* <BuyChannel
                btcUsdPrice={megalith.megalithDataFrozen?.data.btc_usd_price}
                fastestFee={megalith.megalithDataFrozen?.data.fastest_fee}
              /> */}
            {/* <Text mt="lg" size="md">
                If you would like a free incoming channel, please send us a
                message on Telegram. Include the following info:
              </Text> */}
            {/* <Text size="md">- your node public key</Text> */}
            {/* <Text size="md">
                - monthly payment volume expected (sats per month)
              </Text> */}

            {/* <Box mt="lg" maw="md">
                <Button component="a" href="/contact">
                  Contact The Megalith Node
                </Button>
              </Box> */}
            {/* </Paper> */}

            <Title order={4}>💡 Ready to learn more about Lightning?</Title>
            <Text size="md">
              For background info, and technical help, check out our{" "}
              <Anchor href="https://docs.megalithic.me">
                opinionated guide
              </Anchor>
              , designed for new entrants to Lightning. Browse the{" "}
              <Anchor href="/power_list">power list</Anchor> to learn about the
              biggest players on the network. If you're interested in Taproot
              Assets, we're maintaining a beta{" "}
              <Anchor href="/taproot_assets">buy & sell exchange</Anchor> and{" "}
              <Anchor href="/taproot_assets_explorer">
                taproot assets explorer
              </Anchor>
              .
            </Text>
            <Title order={4}>
              ⚙️ Looking for a Lightning Service Provider (LSP)?
            </Title>
            <Text size="md">
              If you're building a wallet or other application, and you need an
              automated API service to open channels for your users, check out
              our{" "}
              <Anchor href="https://docs.megalithic.me/lightning-services/lsp1-get-inbound-liquidity-for-mobile-clients">
                highly-available http-based LSPS1 service
              </Anchor>
              .
            </Text>
            <Image
              mb="md"
              h={150}
              w="auto"
              fit="contain"
              radius="md"
              src="https://d3u5cu1kr5hxm.cloudfront.net/images/wide-landscape-megalith-branding-crop.webp"
              style={{ verticalAlign: "middle", marginRight: "10px" }}
            />
          </Stack>
        </>
      )}
    </>
  );
});

export default MegalithContainer;
